<!--
 * @Author: xwn 3299704183@qq.com
 * @Date: 2022-11-08 17:06:02
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-08-02 19:05:53
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\CalendarManagement\AddCalendar.vue
 * @Description: 校历管理-添加/编辑弹框
-->

<template>
  <el-form class="common-form" :model="ruleForm" :rules="rules" label-width="auto" ref="formRef">
    <el-form-item label="名称" prop="name">
      <el-input
        v-model="ruleForm.name"
        placeholder="请输入名称"
        clearable
        maxlength="20"
        show-word-limit
        class="input-width-medium padding-right-setting"
      ></el-input>
    </el-form-item>
    <el-form-item label="开始日期" prop="startTime">
      <div class="date_box">
        <el-date-picker
          v-model="ruleForm.startTime"
          type="date"
          style="width: 452px"
          :picker-options="startTime"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          @input="changeData"
        >
        </el-date-picker>
        <i class="el-icon-date data_icon"></i>
      </div>
    </el-form-item>
    <el-form-item label="结束日期" prop="endTime">
      <div class="date_box">
        <el-date-picker
          v-model="ruleForm.endTime"
          type="date"
          style="width: 452px"
          :picker-options="endTime"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          @input="changeData"
        >
        </el-date-picker>
        <i class="el-icon-date data_icon"></i>
      </div>
    </el-form-item>
    <el-form-item label="第一周" prop="firstWeekd">
      <div class="date_box">
        <el-date-picker
          v-model="ruleForm.firstWeekd"
          type="week"
          :format="
            ruleForm.firstWeekStartTime + ' ~ ' + ruleForm.firstWeekEndTime
          "
          style="width: 452px"
          :picker-options="{firstDayOfWeek: 1}"
          @input="cc"
          placeholder="选择周"
        >
        </el-date-picker>
        <i class="el-icon-date data_icon"></i>
      </div>
    </el-form-item>

    <div style="text-align: right">
      <xk-button @click="handleClose" type="info">取消</xk-button>
      <xk-button @click="submitForm(1)" type="primary">确定</xk-button>
      <xk-button v-has-permi="['schoolCalendarManagement:set']" v-if="!ruleForm.id" @click="submitForm(2)" type="primary">确定并设置校历</xk-button>
    </div>
  </el-form>
</template>

<script>
import dayjs from "dayjs";
import moment from "moment";
export default {
  props: {
    ruleForm: {
      type: Object,
    },
    rules: {
      type: Object,
    },
  },
  data() {
    return {
      startTime: {
        disabledDate: (time) => {
          let endDateVal = this.ruleForm.endTime;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          }
        },
        firstDayOfWeek: 1,
      },
      endTime: {
        disabledDate: (time) => {
          let beginDateVal = this.ruleForm.startTime;
          if (beginDateVal) {
            return (
              time.getTime() <
              new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000
            );
          }
        },
        firstDayOfWeek: 1,
      },

      // firstWeekd: {
      //   disabledDate: (time) => {
      //     let beginDateVal = this.ruleForm.startTime;
      //     let endDateVal = this.ruleForm.endTime;
      //     if (beginDateVal || endDateVal) {
      //       return (
      //         time.getTime() > new Date(endDateVal).getTime() ||
      //         time.getTime() <
      //           new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000
      //       );
      //     }
      //   },
      //   firstDayOfWeek: 1,
      // },
    };
  },
  created() {
    this.start = this.ruleForm.startTime;
    this.end = this.ruleForm.endTime;
  },
  methods: {
    cc() {
      if (this.ruleForm.startTime == "" || this.ruleForm.endTime == "") {
        this.$message.warning("请先填写开始日期和结束日期");
        this.ruleForm.firstWeekd = "";
      } else {
        console.log(this.ruleForm.firstWeekd,'是我选中的日期吗');
        let weekOfday = moment(this.ruleForm.firstWeekd).format("E");
        console.log(weekOfday,"bbbbbbbbbbb");
        let Monday = moment(this.ruleForm.firstWeekd)
          .subtract(weekOfday - 1, "days")
          .format("YYYY-MM-DD"); //周一日期
        let Sunday = moment(this.ruleForm.firstWeekd)
          .add(7 - weekOfday, "days")
          .format("YYYY-MM-DD"); //周日日期
        console.log(Monday, Sunday);
        //判断第一周的开始时间是否大于开始日期
        console.log(this.ruleForm.startTime, Monday, "判断那个日期大");

      //如果周结束日期小于开始日期
      if(Date.parse(this.ruleForm.startTime) > Date.parse(Sunday)){
                this.$message.warning("请选择开始日期和结束日期之内的时间")
                this.ruleForm.firstWeekd = "";
              }
        if(Date.parse(this.ruleForm.endTime) < Date.parse(Monday)){
                this.$message.warning("请选择开始日期和结束日期之内的时间")
                this.ruleForm.firstWeekd = "";
              }
        if (Date.parse(this.ruleForm.startTime) > Date.parse(Monday)) {
          this.ruleForm.firstWeekStartTime = moment(this.ruleForm.startTime).format("YYYY年MM月DD日");
          console.log("大",this.ruleForm.firstWeekStartTime);
        } else {
            this.ruleForm.firstWeekStartTime = moment(Monday).format("YYYY年MM月DD日");
          console.log("小",this.ruleForm.firstWeekStartTime);
        }

        if (Date.parse(this.ruleForm.endTime) < Date.parse(Sunday)) {
          this.ruleForm.firstWeekEndTime = moment(this.ruleForm.endTime).format("YYYY年MM月DD日");
          console.log("大",this.ruleForm.firstWeekStartTime);
        } else {
            this.ruleForm.firstWeekEndTime = moment(Sunday).format("YYYY年MM月DD日");
          console.log("小",this.ruleForm.firstWeekStartTime);
        }


      }
    },
    /**
     * @Description: 添加/编辑-保存
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-11 10:36:32
     */
    submitForm(type) {
      this.$refs.formRef.validate((val) => {
        if (val) {
          this.$emit("submit", type);
        }
      });
    },
    handleClose() {
      this.$emit("close");
    },
    /**
     * @Description: 选择第一周的时间
     * @DoWhat: 获取一周的开始时间和结束时间
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-10 16:09:21
     */
    changeData() {
      console.log("执行了吗？");
      if(!this.ruleForm.startTime  || !this.ruleForm.endTime ) {
        console.log("执行了吗1111？");
        this.ruleForm.firstWeekd = "";
        this.ruleForm.firstWeekStartTime = ''
        this.ruleForm.firstWeekEndTime = ''
      }
      // this.ruleForm.firstWeekStartTime = dayjs(this.ruleForm.firstWeekd).startOf('week').format('YYYY年MM月DD日')
      // this.ruleForm.firstWeekEndTime = dayjs(this.ruleForm.firstWeekd).endOf('week').format('YYYY年MM月DD日')
    },
  },
};
</script>

<style scoped lang="scss">
.padding-right-setting {
    /deep/ input {
        padding-right: 0px !important;
    }
    /deep/ .el-input__suffix {
        right: 5px !important;
    }
}
/deep/ .el-input__prefix {
  display: none !important;
}
/deep/ .el-input--prefix .el-input__inner {
  padding-left: 15px;
}
/deep/.el-input__suffix {
  right: 30px !important;
}
.date_box {
  position: relative;
  width: fit-content;
  .data_icon {
    position: absolute;
    top: 50%;
    right: 15px;
    z-index: 9;
    color: #c0c4cc;
    font-size: 14px;
    transform: translateY(-50%);
  }
}
</style>
