<template>
    <div class="content-bg">
        <global-page-back
            :detailPageTitle="detailPageTitle"
            @handlerGlobalType="clickBackBtn"
        ></global-page-back>

        <div v-loading="loading" class="calendar-content">
            <div class="table-wrap">
                <div class="table-title">{{ calendarTitle }}</div>
                <el-table
                    :data="tableData"
                    border
                    :span-method="objectSpanMethod"
                    :cell-style="cellStyle"
                    :header-cell-style="headerCellStyle"
                    class="table-content"
                >
                    <el-table-column
                        prop="month"
                        label="月份"
                        width="70"
                        align="center"
                        class-name="first-column"
                    ></el-table-column>
                    <el-table-column
                        prop="week"
                        width="100"
                        align="center"
                    >
                        <template slot="header" slot-scope="scope">
                            <div class="table-header-icon">
                                <div class="headerCon1">周次</div>
                                <div class="headerCon2">星期</div>
                                <div class="headerCon3">日期</div>
                                <div class="headerLine"></div>
                                <div class="headerLine1"></div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-for="item in tableColumn"
                        :key="item.prop"
                        v-bind="item"
                        align="center"
                    >
                        <template #default="{ row, column, $index }">
                            <div
                                v-if="row[item.prop]"
                                @click="handleClick(null, row[item.prop])"
                                :class="[
                                    'calendar-box',
                                    {'has-event': row[item.prop].eventList && row[item.prop].eventList.length > 0}
                                ]"
                            >
                                <div class="calendar-date">{{ row[item.prop].day }}</div>
                                <div
                                    v-if="row[item.prop].eventList && row[item.prop].eventList.length > 0"
                                    class="calendar-list"
                                    @lick.stop
                                >
                                    <el-popover
                                        v-for="subItem in row[item.prop].eventList.slice(0, 2)"
                                        :key="subItem.id"
                                        placement="right"
                                        width="180"
                                        trigger="hover"
                                    >
                                        <div class="popover-content">
                                            <div class="popover-content-title">
                                                {{ `${row[item.prop].day}号` }}
                                            </div>
                                            <div
                                                class="popover-content-item circle-icon"
                                                @click.stop="handleClick(subItem)"
                                            >{{ subItem.eventTitle }}</div>
                                        </div>
                                        <div
                                            slot="reference"
                                            class="list-item ellipsis circle-icon"
                                            @click.stop="handleClick(subItem)"
                                        >
                                            {{ subItem.eventTitle }}
                                        </div>
                                    </el-popover>

                                    <el-popover
                                        v-if="row[item.prop].eventList.length > 2"
                                        placement="right"
                                        width="180"
                                        trigger="hover"
                                    >
                                        <div class="popover-content">
                                            <div class="popover-content-title">
                                                {{ `${row[item.prop].day}号` }}
                                            </div>
                                            <div
                                                v-for="item2 in row[item.prop].eventList"
                                                :key="item2.id"
                                                class="popover-content-item circle-icon"
                                                @click.stop="handleClick(item2)"
                                            >
                                                {{ item2.eventTitle }}
                                            </div>
                                        </div>
                                        <div slot="reference" class="rest-count">
                                            还有{{ row[item.prop].eventList.length - 2 }}项
                                        </div>
                                    </el-popover>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <dialog-wrapper :dialogObj="dialogObj" @handleClose="handleClose">
            <el-form
                v-loading="deleteLoading"
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="95px"
                class="common-form"
            >
                <el-form-item label="日期" prop="date">
                    <el-date-picker
                        v-model="ruleForm.date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        class="input-width-medium"
                        :picker-options="pickerOptions"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="事件标题" prop="eventTitle">
                    <el-input
                        v-model="ruleForm.eventTitle"
                        clearable
                        maxlength="10"
                        show-word-limit
                        placeholder="请输入事件标题"
                        class="input-width-medium"
                    ></el-input>
                </el-form-item>
                <el-form-item label="事件描述" prop="eventDescription">
                    <el-input
                        v-model="ruleForm.eventDescription"
                        type="textarea"
                        clearable
                        maxlength="50"
                        show-word-limit
                        rows="3"
                        placeholder="请输入事件描述"
                        class="input-width-medium"
                    ></el-input>
                </el-form-item>
                <div style="text-align: right">
                    <el-button
                        v-if="ruleForm.id"
                        type="danger"
                        @click="handleDelete"
                    >
                        删除
                    </el-button>
                    <el-button @click="handleClose">取消</el-button>
                    <el-button type="primary" :loading="btnLoading" @click="handleSubmit">
                        确定
                    </el-button>
                </div>
            </el-form>
        </dialog-wrapper>

        <dialog-wrapper
            :dialogObj="dialogObj1"
            @handleClose="handleClose1"
            class="event-detail-wrap"
        >
            <div class="event-detail">
                <div class="event-title circle-icon">
                    {{ ruleForm.eventTitle }}
                </div>
                <div class="event-time">
                    <img src="@/assets/images/calendarManage/time.png" alt="">
                    <span>{{ ruleForm.date[0] }}</span>
                    <span class="split">至</span>
                    <span>{{ ruleForm.date[1] }}</span>
                </div>
                <div class="event-des">
                    <img src="@/assets/images/calendarManage/des.png" alt="">
                    <span>{{ ruleForm.eventDescription || '-' }}</span>
                </div>
            </div>
        </dialog-wrapper>
    </div>
</template>

<script>
import { DialogWrapper } from 'common-local';
import { classScheduleModel } from '@/models/ClassSchedule.js';
import GlobalPageBack from '../Sub/GlobalPageBack/index';
import { parseTime } from "@/libs/utils";

export default {
    name: 'lookCalendar',
    components: {
        DialogWrapper,
        GlobalPageBack
    },
    props: {
        storeData: Object
    },
    data() {
        return {
            loading: true,
            deleteLoading: false,
            calendarTitle: '',
            oriTableData: [],
            tableData: [],
            merge: [],  //存放需要合并的行
            pos: '',   //需要合并行下标
            tableColumn: [
                {
                    label: '星期一',
                    prop: 'week1',
                },
                {
                    label: '星期二',
                    prop: 'week2',
                },
                {
                    label: '星期三',
                    prop: 'week3',
                },
                {
                    label: '星期四',
                    prop: 'week4',
                },
                {
                    label: '星期五',
                    prop: 'week5',
                },
                {
                    label: '星期六',
                    prop: 'week6',
                },
                {
                    label: '星期日',
                    prop: 'week7',
                },
            ],
            dialogObj: {
                title: '校历',
                dialogVisible: false,
                width: "auto",
            },
            btnLoading: false,
            pickerOptions: {
                disabledDate: (time) => {
                    const { newStartTime, newEndTime } = this.storeData;
                    const start = new Date(newStartTime).setHours(0, 0, 0, 0);
                    const end = new Date(newEndTime).setHours(23, 59, 59, 999);
                    const current = time.getTime();
                    return current < start || current > end;
                }
            },
            ruleForm: {
                id: '',
                schoolId: '',
                semesterId: '',
                productId: 'cloud_campus',
                date: [],
                eventStartTime: '',
                eventEndTime: '',
                eventTitle: '',
                eventDescription: ''
            },
            rules: {
                date: [{required: true, message: '请选择日期', trigger: 'blur'}],
                eventTitle: [{required: true, message: '请输入事件标题', trigger: 'blur'}],
            },

            dialogObj1: {
                title: '校历详情',
                dialogVisible: false,
                width: "350px",
            },
        }
    },
    computed: {
        isEdit() {
            return this.storeData.type === '2'
        },
        detailPageTitle() {
            return this.isEdit ? '设置校历' : '查看校历'
        },
        cellStyle() {
            return {
                backgroundColor: '#ffffff',
                color: '#333333',
                borderColor: '#E0E0E0',
                fontWeight: 'bold',
                height: '100px'
            }
        },
        headerCellStyle() {
            return {
                backgroundColor: '#EFFCFD',
                color: '#333333',
                borderColor: '#E0E0E0',
                fontWeight: 'bold',
                height: '50px',
                padding: '0',
            }
        }
    },
    mounted() {
        this.init()
        this.$eventDispatch('setGlobalPageType', 'form')
    },
    methods: {
        /**
         * @Description: 初始化获取校历的开始时间和结束时间，及标题
         * @DoWhat: 初始化
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-11-11 16:07:32
         */
        async init() {
            this.calendarTitle = this.storeData.name
            this.ruleForm.schoolId = this.$store.state.schoolId
            this.ruleForm.semesterId = this.storeData.id
            await this.getList()
            this.getCalendarEvent()
        },
        async getList() {
            const ClassScheduleModel = new classScheduleModel();
            await ClassScheduleModel.getSchoolCalendar({id: this.storeData.id}).then((res) => {
                if (res.data.code === '200') {
                    this.oriTableData = res.data.data
                    this.getSpanArr(this.oriTableData)
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        getCalendarEvent() {
            this.loading = true
            this._fet('/school/schoolCalendarEvent/getCalendarEvent', {
                schoolId: this.$store.state.schoolId,
                semesterId: this.storeData.id,
                productId: 'cloud_campus'
            }).then((res) => {
                if (res.data.code === '200') {
                    const eventList = res.data.data

                    const getDate = (year, month, day) => {
                        const date = `${year}-${month}-${day}`.replace(/[年月]/g, "")
                        return parseTime(date, 'y-m-d')
                    }

                    const getTime = (date) => {
                        return new Date(date).getTime()
                    }

                    const getEvent = (date) => {
                        const dateTimeStamp = getTime(date)
                        return eventList.filter(i => getTime(i.eventStartTime) <= dateTimeStamp && getTime(i.eventEndTime) >= dateTimeStamp)
                    }

                    this.tableData = this.oriTableData.map((row) => {
                        const newRow = { ...row }; // 创建row的副本以避免直接修改原始数据
                        this.tableColumn.forEach(({ prop }) => {
                            const day = newRow[prop];
                            if (day) {
                                const date = getDate(newRow.year, newRow.month, day);
                                const eventList = getEvent(date);
                                newRow[prop] = {
                                    day: day,
                                    date: date,
                                    eventList: eventList,
                                };
                            }
                        });
                        return newRow;
                    });
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            }).finally(() => {
                this.loading = false
            })
        },

        /**
         * @Description: 合并月份单元格
         * @DoWhat: 把校历的相同月份合并
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-11-11 16:06:11
         */
        getSpanArr(data) {
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    this.merge.push(1);
                    this.pos = 0
                } else {
                    if (data[i].month === data[i - 1].month) {
                        this.merge[this.pos] += 1;
                        this.merge.push(0);
                    } else {
                        this.merge.push(1);
                        this.pos = i;
                    }
                }
            }
        },
        objectSpanMethod({rowIndex, columnIndex}) {
            if (columnIndex === 0) {
                const _row = this.merge[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col
                }
            }
        },

        handleClick(data, row) {
            Object.keys(this.ruleForm).forEach((key) => {
                if (data && data[key]) {
                    this.ruleForm[key] = data[key];
                }
            })
            if (data) {
                this.dialogObj.title = '校历'
                this.ruleForm.date = [data.eventStartTime, data.eventEndTime]
            } else {
                this.dialogObj.title = '添加校历'
                this.ruleForm.date = [row.date, row.date]
            }
            if (this.isEdit) {
                this.dialogObj.dialogVisible = true
            } else if (data) {
                this.dialogObj1.dialogVisible = true
            }
        },
        handleSubmit() {
            this.$refs.ruleForm.validate((val) => {
                if (val) {
                    this.btnLoading = true
                    let form = this._.cloneDeep(this.ruleForm)
                    form.eventStartTime = form.date[0]
                    form.eventEndTime = form.date[1]
                    this._fet('/school/schoolCalendarEvent/setCalendarEvent', form).then((res) => {
                        if (res.data.code === '200') {
                            this.$message.success('提交成功')
                            this.getCalendarEvent()
                            this.handleClose()
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg)
                        }
                    }).finally(() => {
                        this.btnLoading = false
                    })
                }
            })
        },
        // 删除校历事件
        handleDelete() {
            this.$confirm('确定要删除吗？','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: "warning"
            }).then(()=> {
                this.deleteLoading = true
                this._fet('/school/schoolCalendarEvent/delete', {
                    id: this.ruleForm.id,
                    schoolId: this.ruleForm.schoolId,
                }).then((res) => {
                    if (res.data.code === '200') {
                        this.$message.success('删除成功')
                        this.handleClose()
                        this.getCalendarEvent()
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }).finally(() => {
                    this.deleteLoading = false
                })
            }).catch(()=> {
            })
        },
        // 关闭添加/编辑弹窗
        handleClose() {
            this.dialogObj.dialogVisible = false
            this.resetRuleForm()
            this.$nextTick(() => {
                this.$refs.ruleForm.clearValidate()
            })
        },
        // 关闭校历详情详情弹窗
        handleClose1() {
            this.resetRuleForm()
            this.dialogObj1.dialogVisible = false
        },
        resetRuleForm() {
            this.ruleForm.date = []
            this.ruleForm.id = ''
            this.ruleForm.eventTitle = ''
            this.ruleForm.eventDescription = ''
            this.ruleForm.eventStartTime = ''
            this.ruleForm.eventEndTime = ''
        },

        // 点击返回
        clickBackBtn() {
            this.$eventDispatch('setGlobalPageType', 'list')
            this.$emit('changeComp', {
                index: 0,
                data: {}
            })
        },
    }
}
</script>
<style scoped lang="scss">
.calendar-content {
    height: calc(100vh - 123px);
    overflow: auto;
    padding: 10px 0;
    box-sizing: border-box;

    .table-wrap {
        min-height: calc(100% - 20px);
        background-color: #fff;
        width: 70%;
        margin: 0 auto;
        padding: 0 20px 20px;
        border-radius: 4px 0 0 4px;
        overflow: hidden;
        box-shadow: -2px 0 8px 0 #E1E3E6;

        .table-title {
            height: 48px;
            line-height: 48px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #2B2E33;
        }

        .el-table {
            border-color: #E0E0E0;

            &:after,
            &:before {
                background-color: #E0E0E0;
            }

            ::v-deep .cell {
                padding: 0;
            }

            ::v-deep .first-column {
                background-color: #EFFCFD !important;
            }

            ::v-deep .el-table tbody tr:hover>td:not(:first-child) {
                background-color: #ffffff !important;
            }
        }
    }
}

.table-header-icon {
    height: 50px;
    position: relative;

    .headerCon1 {
        position: absolute;
        left: 4px;
        bottom: 0;
    }

    .headerCon2 {
        position: absolute;
        right: 4px;
        top: 0;
    }

    .headerCon3 {
        position: absolute;
        left: 24px;
        top: 7px;
    }

    .headerLine {
        width: 1px;
        height: 75px;
        transform: rotate(-47deg);
        transform-origin: 100% 100%;
        background-color: #E2E2E2;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .headerLine1 {
        width: 1px;
        height: 112px;
        transform: rotate(-74deg);
        transform-origin: 100% 100%;
        background-color: #E2E2E2;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

.calendar-box {
    cursor: pointer;
    padding: 6px 0;
    font-weight: 400;
    text-align: left;
    height: 100px;
    box-sizing: border-box;

    .calendar-date {
        font-size: 14px;
        margin-left: 21px;
    }

    .calendar-list {
        display: flex;
        flex-direction: column;
        font-size: 12px;

        .list-item {
            position: relative;
            height: 22px;
            line-height: 22px;
            border-radius: 2px;
            font-size: 12px;
            margin: 0 2px;
            transition: .3s background-color;
            padding-left: 22px;

            &:before {
                left: 9px;
            }

            &:hover {
                background-color: #D9E6FF;
            }
        }

        .rest-count {
            color: #B2B2B2;
            padding-left: 25px;
        }
    }
}

.has-event {
    transition: .3s background-color;
    background-color: #F5F6FA;

    &:hover {
        background-color: #EBF2FF;
    }
}

.popover-content {
    z-index: 99;
    min-height: 106px;
    font-size: 14px;
    color: #333333;

    .popover-content-title {
        text-align: center;
        font-weight: bold;
        margin-bottom: 6px;
    }

    .popover-content-item {
        cursor: pointer;
        position: relative;
        padding-left: 20px;
        margin-bottom: 6px;
        height: 25px;
        line-height: 25px;
        border-radius: 2px;
        transition: .3s background-color;
        overflow: hidden;

        &:before {
            left: 6px;
        }

        &:hover {
            background: #EBF2FF;
        }
    }
}

.event-detail-wrap ::v-deep .el-dialog__body {
    padding: 20px 24px 60px 38px;
    margin: 0;
}

.event-detail {
    .event-title {
        position: relative;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        margin-bottom: 9px;

        &:before {
            left: -14px;
        }
    }

    .event-time, .event-des {
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        color: #666666;
        line-height: 21px;

        img {
            margin-top: 4px;
            margin-right: 6px;
        }
    }

    .event-time {
        margin-bottom: 6px;

        .split {
            margin: 0 6px;
        }
    }
}

.circle-icon::before {
    content: "";
    width: 8px;
    height: 8px;
    background: #3C7FFF;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
</style>
